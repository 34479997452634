.request-support-cases .ant-table-content td > i {
  color: #0050b3;
}
.request-support-cases .ant-table-content td.status-unplanned span {
  color: #f5222d;
  border-color: #f5222d;
  background-color: #00000000;
  font-weight: 100;
}
.request-support-cases .ant-table-content td.status-planned span {
  color: #52c41a;
  border-color: #52c41a;
  background-color: #00000000;
  font-weight: 100;
}
.request-support-cases .ant-table-content td.status-declined span {
  color: #0050b3;
  border-color: #0050b3;
  background-color: #00000000;
  font-weight: 100;
}
