.casedetail-layout {
  padding: 1.5rem 1rem 0;
}
.casedetail-layout > .casedetail-title {
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 700;
  font-size: 1.75rem;
}
.casedetail-layout > .casedetail-title > button {
  margin-left: 0.5rem;
}
.casedetail-layout > .casedetail-title > span {
  margin-left: 0.5rem;
  border: none;
  box-shadow: none;
  position: relative;
  bottom: 0.34rem;
}
.casedetail-layout > .casedetail-subtitle {
  color: #000;
  padding-bottom: 1rem;
}
.casedetail-layout > .container {
  justify-items: stretch;
  align-items: stretch;
  display: grid;
  grid-column-gap: 2rem;
  padding: 0;
}
.casedetail-layout > .container .card-body-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 1rem;
}
.casedetail-layout > .container .planning-info {
  height: 100%;
}
.casedetail-layout > .container .last-modified {
  position: initial;
  padding: 1rem 0.5rem 0 0;
}
.casedetail-layout > .container .planning-infocard .ant-btn.ant-btn-primary {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.casedetail-layout > .container .ant-card-body {
  overflow: hidden;
  overflow-y: visible;
}
.casedetail-layout > .container .ant-card-body .spinner {
  text-align: center;
}
.casedetail-layout > .container .ant-card-body .patient-empty {
  height: 11.375rem;
}
.casedetail-layout > .container .ant-card-body .surgery-empty {
  height: 3.125rem;
}
.casedetail-layout > .container .ant-card-body .notes-empty {
  height: 4.875rem;
}
.casedetail-layout > .container .ant-card-body .planning-empty {
  height: calc(100vh - 7.5rem);
}
.casedetail-layout > .container .ant-card-head-title {
  max-width: fit-content;
}
@media screen and (min-width: 70rem) {
  .container-columns {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}
@media screen and (max-width: 70rem) {
  .container-columns {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr;
  }
}
.submit-button {
  margin-top: 1rem;
  float: right;
}
