.two-col {
  display: grid;
  grid-template-columns: 4fr 8fr;
  padding-bottom: 1rem;
}
.three-col {
  display: grid;
  grid-template-columns: 0.75fr 3fr 4fr;
  padding-bottom: 1rem;
}
.info-container {
  font-size: 1rem;
  align-items: center;
}
.info-container .info-title {
  font-size: 1rem;
}
.info-container .info-content {
  padding-left: 0;
}
.info-container .info-icon {
  font-size: 1rem;
  border-width: 0;
  color: #fff;
}
.info-container .info-icon.in-progress {
  color: #0050b3;
}
.info-container .info-icon.completed {
  color: #337313;
}
.info-container .info-icon.new {
  color: #bfbfbf;
}
.info-container .info-icon.error {
  color: #f5222d;
}
.info-container .info-icon > i {
  padding-top: 0.2rem;
  padding-right: 0.1rem;
  font-size: 1.3rem;
}
.info-container a {
  display: flex;
  justify-content: center;
  color: #003a8c;
  text-decoration: underline;
  font-size: 1rem;
}
