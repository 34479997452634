div.quality-content {
  border: 1px solid #202020;
  border-radius: 4px;
  color: #202020;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  margin-right: 0.5rem;
}
div.quality-content.high {
  border-color: #52c41a;
  color: #52c41a;
}
div.quality-content.medium {
  border-color: #fa8c16;
  color: #fa8c16;
}
div.quality-content.low {
  border-color: #f5222d;
  color: #f5222d;
}
span.ant-tree-node-selected div.quality-content,
div.title.full-row-item div.quality-content {
  background-color: #202020;
  color: #fff;
}
span.ant-tree-node-selected div.quality-content.high,
div.title.full-row-item div.quality-content.high {
  border-color: #52c41a;
  background-color: #52c41a;
}
span.ant-tree-node-selected div.quality-content.medium,
div.title.full-row-item div.quality-content.medium {
  border-color: #fa8c16;
  background-color: #fa8c16;
}
span.ant-tree-node-selected div.quality-content.low,
div.title.full-row-item div.quality-content.low {
  border-color: #f5222d;
  background-color: #f5222d;
}
.ct {
  color: #fff !important;
  border-color: #43a8c7 !important;
  background-color: #43a8c7 !important;
  width: 1.938rem;
  text-align: center;
}
.mr {
  color: #fff !important;
  border-color: #8f5bd6 !important;
  background-color: #8f5bd6 !important;
  width: 1.938rem;
  text-align: center;
}
