.case-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem 3rem;
  flex-direction: column;
}
.case-info .ticket-info {
  border: 0.15rem solid #e8e8e8;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 62.5rem) {
  .case-info .ticket-info {
    width: 54rem;
    height: 28rem;
  }
}
@media (max-width: 62.5rem) {
  .case-info .ticket-info {
    width: 100%;
  }
}
@media (min-width: 62.5rem) {
  .case-info .ticket-info .result-icon {
    width: 20rem;
  }
}
@media (max-width: 62.5rem) {
  .case-info .ticket-info .result-icon {
    width: 80%;
  }
}
.case-info .ticket-info .title {
  padding: 1rem 0 0 0;
  margin-bottom: 0.3rem;
  font-size: 1.3rem;
}
.case-info .ticket-info .link {
  margin-bottom: 2.5rem;
  text-decoration: underline;
}
