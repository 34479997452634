body {
  font-family: Rubik, -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  user-select: none;
}
.container {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-layout {
  background: #fff;
  margin-left: 1.25rem;
}
.ant-layout a {
  color: #030852;
}
.ant-layout-header {
  background: #00285a;
  color: #fff;
}
.ant-layout-header .title {
  font-size: 1.75rem;
  color: #fff;
}
.main-layout {
  display: flex;
  user-select: none;
  -webkit-user-select: none;
}
.main-layout .main-content {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}
.ant-modal-content {
  user-select: none;
  -webkit-user-select: none;
}
.ant-btn.ant-btn-primary {
  background-color: #0050b3;
  border-color: #0050b3;
}
.ant-btn.ant-btn-default {
  color: #0050b3;
  border-color: #0050b3;
}
.secured-button {
  box-shadow: 0 0.125rem 0 #00000028;
  border-radius: 4px;
  background-color: #0050b3;
  border-color: #0050b3;
  color: #fff;
}
.secured-button:hover,
.secured-button:focus {
  background-color: #096dd9;
  border-color: #096dd9;
}
.secured-button:disabled {
  color: #8c8c8c;
  box-shadow: 0 0.125rem 0 #00000028;
}
.secured-button:disabled:hover {
  color: #8c8c8c;
}
