.ant-table-filter-dropdown .ant-input {
  margin-bottom: 0.5rem;
}
.ant-table-filter-dropdown .buttons-search {
  text-align: center;
}
.ant-table-filter-dropdown .buttons-search .ant-btn.ant-btn-primary {
  background-color: #0050b3;
  border-color: #0050b3;
  margin-right: 0.5rem;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: column;
}
.ant-layout.ant-layout-has-sider .cases-list .ant-btn.ant-btn-primary {
  background-color: #fff;
  border-color: #d9d9d9;
  color: #595959;
}
.ant-layout.ant-layout-has-sider .cases-list .view-case-icon {
  color: #0050b3;
}
.ant-layout.ant-layout-has-sider .cases-list-pagination {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.ant-table-row {
  cursor: pointer;
}
.ant-table-row .cases-list-icon-container {
  display: flex;
  justify-content: flex-start;
}
.ant-table-row i.anticon-copy,
.ant-table-row i.anticon-eye,
.ant-table-row i.anticon-file-pdf {
  visibility: hidden;
  padding-right: 0.5rem;
}
.ant-table-tbody > tr > td {
  max-width: 10rem;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: #e6f7ff;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-copy,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-eye,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-file-pdf {
  color: #096dd9;
  display: block;
  visibility: visible;
}
@media (min-width: 72.125rem) {
  .ant-table-body {
    overflow-x: hidden !important;
  }
}
.ant-select-selection:hover {
  border-color: #1890ff;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #1890ff;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #0050b3;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0050b3;
  border-color: #0050b3;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0050b3;
}
.ant-table-filter-dropdown-link {
  color: #0050b3;
}
.ant-table-filter-dropdown-link:hover {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:active {
  color: #0050b3;
}
.filtered-column {
  color: #0050b3 !important;
}
.ant-table-content td > i {
  color: #0050b3;
}
.ant-table-content td.status-unplanned span {
  color: #f5222d;
  border-color: #f5222d;
  background-color: #00000000;
  font-weight: 100;
  padding: 0 0.2rem 0 0.2rem;
}
.ant-table-content td.status-planned span {
  color: #52c41a;
  border-color: #52c41a;
  background-color: #00000000;
  font-weight: 100;
  padding: 0 0.2rem 0 0.2rem;
}
.ant-table-content td.status-declined span {
  color: #0050b3;
  border-color: #0050b3;
  background-color: #00000000;
  font-weight: 100;
  padding: 0 0.2rem 0 0.2rem;
}
