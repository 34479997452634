.case-task {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  flex-direction: column;
}
.case-task .task-name {
  font-weight: bold;
}
.case-task .task-subtitle {
  color: #8c8c8c;
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
}
.case-task .task-link {
  color: #595959;
  font-size: 1rem;
  text-align: center;
}
.case-task .ticket-info {
  border: 0.15rem solid #e8e8e8;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 62.5rem) {
  .case-task .ticket-info {
    width: 54rem;
  }
}
@media (max-width: 62.5rem) {
  .case-task .ticket-info {
    width: 100%;
  }
}
.case-task .ticket-info .info {
  display: flex;
  justify-content: space-evenly;
  width: inherit;
  text-align: center;
  padding-top: 3rem;
}
.case-task .ticket-info .info p {
  font-size: 1.5rem;
  color: #0050b3;
  font-weight: 600;
}
.case-task .ticket-info .info p > label {
  font-size: 1.25rem;
  color: #000;
}
@media (min-width: 25rem) {
  .case-task .ticket-info .info p > label {
    margin-left: 1.5rem;
  }
}
.case-task .ticket-info .jump-icon {
  margin-bottom: 4rem;
}
.case-task .ticket-info .ant-upload-drag,
.case-task .ticket-info .ant-upload-drag-uploading {
  background-color: #fff;
}
@media (min-width: 62.5rem) {
  .case-task .ticket-info .ant-upload-drag,
  .case-task .ticket-info .ant-upload-drag-uploading {
    width: 32rem;
    height: 14rem;
  }
}
@media (max-width: 62.5rem) {
  .case-task .ticket-info .ant-upload-drag,
  .case-task .ticket-info .ant-upload-drag-uploading {
    width: 21rem;
    height: 14rem;
  }
}
.case-task .ticket-info .ant-upload-drag .container,
.case-task .ticket-info .ant-upload-drag-uploading .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.case-task .ticket-info .ant-upload-drag .ant-upload-drag-icon,
.case-task .ticket-info .ant-upload-drag-uploading .ant-upload-drag-icon {
  height: 6rem;
  width: 6rem;
  position: relative;
  top: -2rem;
}
.case-task .ticket-info .ant-upload-drag .ant-upload-drag-message,
.case-task .ticket-info .ant-upload-drag-uploading .ant-upload-drag-message {
  text-decoration: underline;
  color: #0050b3;
}
.case-task .ticket-info .ant-form .ant-input:active,
.case-task .ticket-info .ant-form .ant-input:focus,
.case-task .ticket-info .ant-form .ant-input:hover,
.case-task .ticket-info .ant-form .ant-select-selection:active,
.case-task .ticket-info .ant-form .ant-select-selection:focus,
.case-task .ticket-info .ant-form .ant-select-selection:hover {
  border-color: #00285a;
}
.case-task .ticket-info .ant-form .ant-form-item-with-help {
  margin-bottom: 0.313rem;
}
.case-task .ticket-info .ant-form .ant-form-item {
  line-height: 1rem;
}
@media (min-width: 62.5rem) {
  .case-task .ticket-info .ant-form .ant-form-item {
    width: 32rem;
  }
}
@media (max-width: 62.5rem) {
  .case-task .ticket-info .ant-form .ant-form-item {
    width: 21rem;
  }
}
.case-task .ticket-info .ant-form .icon-color {
  color: #0000003f;
}
.case-task .ticket-info svg {
  width: inherit;
}
.case-task .ticket-info .footer-buttons {
  text-align: center;
  position: relative;
  margin-top: auto;
}
@media (max-width: 25rem) {
  .case-task .ticket-info .footer-buttons .btn-back {
    margin-bottom: 1rem;
  }
}
@media (min-width: 25rem) {
  .case-task .ticket-info .footer-buttons .btn-back {
    margin-right: 1rem;
  }
}
.case-task .ticket-info .ant-upload-list.ant-upload-list-text {
  height: 3rem;
}
.case-task .ant-form-explain,
.case-task .ant-form-extra {
  clear: both;
  min-height: 1.375rem;
  margin-top: -0.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #f5222d;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.case-task .ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.case-task .ant-upload-list-item-info .anticon-loading,
.case-task .ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 0.313rem;
  color: #8c8c8c;
  font-size: 0.813rem;
}
.case-task .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6f7ff;
}
