.userform-container {
  padding-top: 0.625rem;
}
.userform-container .resetpassword-button {
  float: right;
  color: #0050b3;
  top: 0.4rem;
}
.userform-container .resetpassword-button:hover,
.userform-container .resetpassword-button:focus {
  color: #0050b3;
}
.userform-container .createuser-button {
  margin-right: 0.5rem;
  box-shadow: 0 0.125rem 0 #00000028;
  border-radius: 4px;
  background-color: #0050b3;
  border-color: #0050b3;
  color: #fff;
}
.userform-container .createuser-button:hover,
.userform-container .createuser-button:focus {
  background-color: #096dd9;
  border-color: #096dd9;
}
.userform-container .createuser-button:disabled {
  color: #8c8c8c;
  box-shadow: 0 0.125rem 0 #00000028;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.userform-container .createuser-button:disabled:hover {
  color: #8c8c8c;
}
.userform-container .new-user-form {
  width: 30rem;
}
.userform-container .ant-form-item-with-help {
  margin-bottom: 0.313rem;
}
.userform-container .ant-form-explain,
.userform-container .ant-form-extra {
  clear: both;
  min-height: 1.375rem;
  margin-top: -0.125rem;
  color: #f5222d;
  font-size: 0.875rem;
  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.userform-container .ant-switch-checked {
  background-color: #0050b3;
}
.userform-container .ant-card-body {
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #91d5ff;
}
.ant-select-dropdown-menu-item-selected {
  color: #202020;
  font-weight: 600;
  background-color: #fff;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #fff;
}
