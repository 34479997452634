.request-support-modal .ant-modal,
.request-support-modal .ant-modal-content {
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0 !important;
  float: right;
  border-radius: 0;
  padding-bottom: 0;
  user-select: text !important;
}
.request-support-modal .ant-modal .ant-modal-close-x,
.request-support-modal .ant-modal-content .ant-modal-close-x {
  line-height: 3rem;
  color: #000;
}
.request-support-modal .ant-modal-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #000;
}
.request-support-modal .ant-modal-header .ant-modal-title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
}
.request-support-modal .ant-modal-header .ant-modal-close {
  padding-top: 0;
}
.request-support-modal .ant-modal-header .ant-modal-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #000;
}
.request-support-modal .ant-modal-header .ant-modal-header .ant-modal-title {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
}
.request-support-modal .ant-modal-header .ant-modal-body {
  padding: 0;
}
.request-modal-body {
  color: #000;
  background-color: #fff;
}
.request-modal-body .request-modal-element {
  padding: 0 2rem;
}
.request-modal-body .request-modal-element .ticket-icon {
  height: 3.125rem;
  width: 3.125rem;
  margin-left: 1rem;
  position: relative;
  top: 1.125rem;
}
.request-modal-body .request-modal-element .element-title {
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
}
.request-modal-body .border {
  border-top: 1px solid #000;
  padding-bottom: 1rem;
}
.request-modal-body .border-light {
  border-top: 1px solid #e8e8e8;
  padding-bottom: 1rem;
}
.request-modal-body .spinner {
  text-align: center;
  padding-bottom: 2.5rem;
}
.request-modal-body .icon-container {
  text-align: center;
  padding-bottom: 2.281rem;
}
.request-modal-body .icon-container > .error-icon {
  font-size: 1.25rem;
  color: #030852;
}
