.ant-menu-submenu-popup {
  height: 0 !important;
}
.breadcrumb-container .ant-breadcrumb {
  height: 4rem;
  padding-top: 0.688rem;
}
.breadcrumb-container .ant-breadcrumb-separator,
.breadcrumb-container .ant-breadcrumb-link {
  font-size: 1.75rem;
  font-weight: 600;
  color: white;
  white-space: nowrap;
}
.breadcrumb-container .ant-breadcrumb-separator > a,
.breadcrumb-container .ant-breadcrumb-link > a {
  color: white !important;
}
