.ant-table-row {
  cursor: pointer;
}
.ant-table-row i.anticon-eye {
  visibility: hidden;
}
.ticket-table-container .ant-btn.ant-btn-primary {
  background-color: #fff;
  border-color: #d9d9d9;
  color: #595959;
}
.ticket-table-container .view-case-icon {
  color: #0050b3;
}
.ticket-table-container .ant-table-tbody > tr > td {
  max-width: 10rem;
}
.ticket-table-container .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: #e6f7ff;
}
.ticket-table-container .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-eye {
  color: #096dd9;
  display: block;
  visibility: visible;
  padding-left: 1rem;
  padding-bottom: 1rem;
  margin: 0.1rem -1rem -1rem -1rem;
}
.ticket-table-container .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-eye::after {
  content: "";
  padding: 2rem 0;
  margin: 0.5rem;
}
.ticket-table-container > .ant-pagination {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
@media (min-width: 72.125rem) {
  .ant-table-body {
    overflow-x: hidden !important;
  }
}
.ant-select-selection:hover {
  border-color: #1890ff;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #1890ff;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #0050b3;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-table-filter-dropdown .ant-input {
  margin-bottom: 0.5rem;
}
.ant-table-filter-dropdown .buttons-search {
  text-align: center;
}
.ant-table-filter-dropdown .buttons-search .ant-btn.ant-btn-primary {
  background-color: #0050b3;
  border-color: #0050b3;
  margin-right: 0.5rem;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0050b3;
  border-color: #0050b3;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0050b3;
}
.ant-table-filter-dropdown-link {
  color: #0050b3;
}
.ant-table-filter-dropdown-link:hover {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:active {
  color: #0050b3;
}
.filtered-column {
  color: #0050b3 !important;
}
