.table-container {
  width: 100%;
}
.table-container .ant-pagination.ant-table-pagination {
  display: flex;
  justify-content: center;
  float: unset;
}
.status-disabled {
  text-align: center;
}
.status-disabled span {
  text-align: center;
  width: 5rem;
  color: #f5222d;
  border-color: #f5222d;
  background-color: #00000000;
  font-weight: 100;
}
.status-enabled {
  text-align: center;
}
.status-enabled span {
  text-align: center;
  width: 5rem;
  color: #52c41a;
  border-color: #52c41a;
  background-color: #00000000;
  font-weight: 100;
}
.ant-table-row {
  cursor: pointer;
}
.ant-table-row i.anticon-edit {
  visibility: hidden;
}
.ant-table-tbody > tr > td {
  max-width: 10rem;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: #e6f7ff;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td i.anticon-edit {
  color: #096dd9;
  display: block;
  visibility: visible;
}
@media (min-width: 72.125rem) {
  .ant-table-body {
    overflow-x: hidden !important;
  }
}
.ant-select-selection:hover {
  border-color: #1890ff;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #1890ff;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #0050b3;
  background-color: #e6f7ff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6f7ff;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}
.ant-pagination-item-active a {
  color: #1890ff;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #91d5ff;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #91d5ff;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #1890ff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #1890ff;
}
.ant-tag {
  margin-right: 0;
}
.ant-table-filter-dropdown .ant-input {
  margin-bottom: 0.5rem;
}
.ant-table-filter-dropdown .buttons-search {
  text-align: center;
}
.ant-table-filter-dropdown .buttons-search .ant-btn.ant-btn-primary {
  margin-right: 0.5rem;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0050b3;
  border-color: #0050b3;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0050b3;
}
.ant-table-filter-dropdown-link {
  color: #0050b3;
}
.ant-table-filter-dropdown-link:hover {
  color: #1890ff;
}
.ant-table-filter-dropdown-link:active {
  color: #0050b3;
}
.filtered-column {
  color: #0050b3 !important;
}
